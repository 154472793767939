import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  fetchApplicationsList,
  fetchApplicationsMyCheck,
} from '../../store/slices/applicationsSlice/applicationsSlice';
import {
  ApplicationsMapSearchType,
  ApplicationsSearchType,
  SearchApplicationsBody,
} from '../../lib/interfaces/SearchApplicationsBody';
import { toSendingFormat } from '../../lib/utils/formatDate';
import { AnyObject } from '../../lib/interfaces/AnyObject';
import { HasAccess } from '../../lib/utils/usePermissions';
import { AppTypes } from '../../lib/const/appTypes';

type SearchField = {
  name: string;
  value: any;
};

type HandleSetSearchFormFields = (field: AnyObject) => void;
type ResetSearchFormFields = () => void;
type GetApplicationsList = () => void;
type GetSearchFormFieldValue = (fieldName: string) => any;
export interface SearchApplicationsState {
  searchFormFields: SearchField[];
  handleSetSearchFormFields: HandleSetSearchFormFields;
  resetSearchFormFields: ResetSearchFormFields;
  getApplicationsList: GetApplicationsList;
  getSearchFormFieldValue: GetSearchFormFieldValue;
}

type IUseSearchApplications = (props: {
  searchType: ApplicationsSearchType | ApplicationsMapSearchType;
  hasAccess: HasAccess;
}) => SearchApplicationsState;

const useSearchApplications: IUseSearchApplications = ({ searchType, hasAccess }) => {
  const dispatch = useAppDispatch();
  const { meUser } = useAppSelector((state) => state.persons);

  const getDefaultStatusId = () => {
    switch (searchType) {
      case 'MAP_ALL':
        return [3];
      case 'MAP_MY':
        return [5];
      default:
        return undefined;
    }
  };

  const defaultSearchFormFields: SearchField[] = [
    { name: 'page', value: 0 },
    { name: 'size', value: searchType === 'MAP_MY' || searchType === 'MAP_ALL' ? 1000 : 10 },
    { name: 'searchType', value: searchType },
    { name: 'toggleForSearch', value: null }, // Значение null, для устранение загрузки по дефолту
    { name: 'appStatusId', value: getDefaultStatusId() },
    { name: 'appStatusIdExclude', value: searchType === 'MAP_MY' || searchType === 'MAP_ALL' ? undefined : [] }, // Для чекбокса "Исключить закрытые"
    { name: 'address', value: undefined },
    { name: 'applicationText', value: undefined },
    { name: 'createDate', value: [null, null] },
    { name: 'closeDate', value: [null, null] },
    { name: 'deadline', value: [null, null] },
    { name: 'executionDate', value: [null, null] },
    { name: 'personCategoryIds', value: undefined },
    { name: 'phone', value: undefined },
    { name: 'projectIds', value: [] },
    { name: 'regionId', value: meUser?.regionId },
    { name: 'responsibleFio', value: undefined },
    { name: 'regularityAssistanceId', value: undefined },
    { name: 'subjectId', value: undefined },
    { name: 'subwayStationId', value: undefined },
    { name: 'urgent', value: undefined },
    { name: 'wardFio', value: undefined },
    { name: 'sortField', value: 'CREATE_DATE' },
    { name: 'liked', value: undefined },
    { name: 'appTypeId', value: [AppTypes['Стандартная']] },
    { name: 'isVisiblePerson', value: false },
    { name: 'personStatusIds', value: undefined },
    { name: 'projectVolunteerIds', value: undefined },
  ];

  // Массив полей формируемый из searchFields для передачи в качестве fields в Form
  const [searchFormFields, setSearchFormFields] = useState<SearchField[]>(defaultSearchFormFields);

  // Функция изменения значений searchFields, ее нужно передать в onValuesChange Form
  const handleSetSearchFormFields: HandleSetSearchFormFields = (fields) => {
    setSearchFormFields((prev) => {
      const updatedFields = prev.map((item) => {
        if (item.name in fields) {
          return { ...item, value: fields[item.name] };
        }
        return item;
      });
      // Сброс пагинации на 0, при изменении значений
      if (!('page' in fields)) {
        updatedFields.forEach((item, i) => {
          if (item.name === 'page') updatedFields[i].value = 0;
        });
      }
      // Изменение флага для запуска поиска просьб для полей: страница, срочность просьбы, сортировка, исключение закрытых
      if (
        'page' in fields ||
        'urgent' in fields ||
        'sortField' in fields ||
        'appStatusIdExclude' in fields ||
        'liked' in fields ||
        'appTypeId' in fields
      ) {
        updatedFields.forEach((item, i) => {
          if (item.name === 'toggleForSearch') {
            updatedFields[i].value = !updatedFields[i].value;
          }
        });
      }
      // Изменение состава поля appStatusId по изменении Исключить закрытые/excludeClosed
      if ('appStatusIdExclude' in fields) {
        const excludeClosed = fields.appStatusIdExclude.length > 0;
        const appStatusId = getSearchFormFieldValue('appStatusId');
        if (excludeClosed && appStatusId) {
          updatedFields.forEach((item, i) => {
            if (item.name === 'appStatusId') {
              updatedFields[i].value = appStatusId.filter((id) => id !== 6);
            }
          });
        }
      }
      return updatedFields;
    });
  };

  // Установка дефолтного значения региона
  useEffect(() => {
    if (meUser?.regionId) {
      // handleSetSearchFormFields({
      //   regionId: hasAccess(["APPLICATIONS.READ.ALLREGIONS"])
      //     ? undefined
      //     : meUser?.regionId,
      // });
      handleSetSearchFormFields({
        regionId: meUser.regionId,
      });
    }
  }, [meUser]);

  const getSearchFormFieldValue: GetSearchFormFieldValue = (fieldName) =>
    searchFormFields.find(({ name }) => name === fieldName)?.value;

  const resetSearchFormFields: ResetSearchFormFields = () => {
    setSearchFormFields((prev) =>
      defaultSearchFormFields.map((item) => {
        if (item.name === 'toggleForSearch') {
          return {
            name: 'toggleForSearch',
            value: !getSearchFormFieldValue('toggleForSearch'),
          };
        }
        // Если у пользователя нет прав на изменение региона, то оставляем, как было.
        if (item.name === 'regionId' && !hasAccess(['APPLICATIONS.READ.ALLREGIONS'])) {
          const prevRegion = prev.find(({ name }) => name === 'regionId');
          if (prevRegion) {
            return prevRegion;
          }
          return item;
        }
        return item;
      })
    );
  };

  const getSearchBody = () => {
    const deletedFields = [
      'page',
      'size',
      'toggleForSearch',
      'createDate',
      'closeDate',
      'deadline',
      'isVisiblePerson',
      'projectIds',
      'personStatusIds',
    ];
    const searchBody: SearchApplicationsBody = searchFormFields
      .filter(({ name }) => !deletedFields.includes(name))
      // Метод поиска ожидает searchType: ALL || MY, для разделения list/map используются MAP_MY/MAP_ALL, т.о. нужно удалить "MAP_"
      .map((field) =>
        field.name === 'searchType' ? Object.assign(field, { value: field.value.replace('MAP_', '') }) : field
      )
      // Удаление полей с пустыми значениями
      .filter(({ value }) => {
        if (value === undefined || value === '' || value === null || (Array.isArray(value) && value.length === 0)) {
          return false;
        }
        return true;
      })
      // Формирование объекта для запроса
      .reduce((result, { name, value }) => ({ ...result, [name]: value }), {});

    // Добавление полей дат
    const createDate = getSearchFormFieldValue('createDate');
    const closeDate = getSearchFormFieldValue('closeDate');
    const deadline = getSearchFormFieldValue('deadline');
    const executionDate = getSearchFormFieldValue('executionDate');
    if (createDate?.[0] && createDate?.[1]) {
      searchBody.createDateFrom = toSendingFormat(createDate[0]);
      searchBody.createDateTo = toSendingFormat(createDate[1]);
    }
    if (closeDate?.[0] && closeDate?.[1]) {
      searchBody.closeDateFrom = toSendingFormat(closeDate[0]);
      searchBody.closeDateTo = toSendingFormat(closeDate[1]);
    }
    if (deadline?.[0] && deadline?.[1]) {
      searchBody.deadlineFrom = toSendingFormat(deadline[0]);
      searchBody.deadlineTo = toSendingFormat(deadline[1]);
    }
    if (executionDate?.[0] && executionDate?.[1]) {
      searchBody.executionDateFrom = toSendingFormat(executionDate[0]);
      searchBody.executionDateTo = toSendingFormat(executionDate[1]);
    }
    return searchBody;
  };

  // Функция загрузки списка просьб
  const getApplicationsList = () => {
    dispatch(
      fetchApplicationsList({
        searchParams: {
          page: getSearchFormFieldValue('page'),
          size: getSearchFormFieldValue('size'),
        },
        searchBody: getSearchBody(),
      })
    );

    dispatch(
      fetchApplicationsMyCheck({
        searchParams: {
          page: 0,
          size: 1,
        },
        searchBody: {
          searchType: 'MY',
        },
      })
    );
  };

  const toggleForSearch = getSearchFormFieldValue('toggleForSearch');
  // TODO Eugene - разобраться, почему загрузка происходит дважды
  // Загрузка списка просьб
  useEffect(() => {
    if (toggleForSearch !== null) {
      getApplicationsList();
    }
  }, [toggleForSearch]);

  return {
    searchFormFields,
    handleSetSearchFormFields,
    resetSearchFormFields,
    getApplicationsList,
    getSearchFormFieldValue,
  };
};

export default useSearchApplications;
