import React, { FC } from 'react';
import { Col, Divider, Row, Form, Select, Input, DatePicker, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getDictionaryItemById, getFio } from '../../lib/utils/applications';
import { toDisplayFormat } from '../../lib/utils/formatDate';
import { useAppSelector } from '../../store/hooks';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { displayFormat } from '../../lib/utils/formatDate';
import selectFilterOption, { selectFilterOptionIcon } from '../../lib/utils/selectFilterOption';
import { AppStatusAliasesEnum, appStatusAliases } from '../../lib/const/appStatusAliases';
import { FindAndFormatLinkForText } from '../common/FindAndFormatLinkForText';
import { ProjectTypes } from '../../lib/const/projectsTypes';
import { datePikerMaskDefault } from '../../lib/const/masks';
import InputMask from 'react-input-mask';

interface IProps {
  editModeMain: boolean;
  editApplicationForm: any;
}

export const ApplicationMainProcessing: FC<IProps> = ({ editModeMain, editApplicationForm }) => {
  const { loadResponsibles, findDynamicSVGReactElement, hasAccess } = useMainContext();
  const { application } = useAppSelector((state) => state.applications);
  const { appSubject, regularityOfAssistance, region, appType } = useAppSelector((state) => state.dictionaries);
  const { projects, projectsLoading } = useAppSelector((state) => state.projects);
  const { responsibles, responsiblesLoading } = useAppSelector((state) => state.persons);

  const renderProcessing = () => {
    const subject = getDictionaryItemById(appSubject, application?.subjectId)?.name;
    const appTypeId = getDictionaryItemById(appType, application?.appTypeId)?.name;
    const regularity = getDictionaryItemById(regularityOfAssistance, application?.regularityAssistanceId)?.name;
    const regionName = getDictionaryItemById(region, application?.regionId)?.name;
    const project = projects.find((item) => item.id === application?.project?.id)?.title;
    const responsibleFio = getFio({
      firstName: application?.responsible?.firstName,
      lastName: application?.responsible?.lastName,
      middleName: application?.responsible?.middleName,
    });
    const deadline = application?.deadline ? toDisplayFormat(moment(application.deadline, 'YYYY-MM-DD')) : '';
    const executionDateText = application?.executionDate
      ? moment(application?.executionDate, 'YYYY-MM-DD').format('DD MMMM')
      : '';
    const executionTimeText = application?.executionTime?.toLocaleLowerCase() || '';
    return (
      <>
        <Row gutter={20}>
          <Col span={10}>
            <Row>
              <Col>
                <div className="application-main__dataLabel">Просьба:</div>
                <div className="pl0">
                  <FindAndFormatLinkForText txtString={application?.text} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="mt10">
                <span className="application-main__dataLabel">Дата исполнения:</span>
                <span className="application-main__dataValue">{`${[executionDateText, executionTimeText]
                  .filter(Boolean)
                  .join(', ')}`}</span>
              </Col>
            </Row>
          </Col>
          <Col span={7}>
            <Row>
              <Col className="display-flex">
                <div className="application-main__dataLabel">Тип просьбы:</div>
                <div className="application-main__dataValue">{appTypeId}</div>
              </Col>
            </Row>
            <Row>
              <Col className="display-flex">
                <div className="application-main__dataLabel">Вид помощи:</div>
                <div className="application-main__dataValue">{subject}</div>
              </Col>
            </Row>
            <Row>
              <Col className="display-flex">
                <div className="application-main__dataLabel">Регулярность помощи:</div>
                <div className="application-main__dataValue">{regularity}</div>
              </Col>
            </Row>
            <Row>
              <Col className="display-flex">
                <div className="application-main__dataLabel">Субъект РФ:</div>
                <div className="application-main__dataValue">{regionName}</div>
              </Col>
            </Row>
            <Row>
              <Col className="display-flex">
                <div className="application-main__dataLabel">Проект:</div>
                <div className="application-main__dataValue">{project}</div>
              </Col>
            </Row>
          </Col>
          <Col span={7}>
            <Row>
              <Col className="display-flex">
                <div className="application-main__dataLabel">Ответственный:</div>
                <div className="application-main__dataValue">
                  <Link to={`/files/${application?.responsible?.id}/general`} target="_blank">
                    {responsibleFio}
                  </Link>
                </div>
              </Col>
            </Row>
            {application?.appTypeId !== 2 && (
              <Row>
                <Col className="display-flex">
                  <div className="application-main__dataLabel">Число исполнителей:</div>
                  <div className="application-main__dataValue">{application?.numberOfPerformers}</div>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="display-flex">
                <div className="application-main__dataLabel">Срочная просьба:</div>
                <div className="application-main__dataValue">{application?.urgent ? 'Да' : 'Нет'}</div>
              </Col>
            </Row>
            <Row>
              <Col className="display-flex">
                <div className="application-main__dataLabel">Срок завершения:</div>
                <div className="application-main__dataValue">{deadline}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const renderProcessingEditing = () => {
    const onSelectRegionId = () => {
      editApplicationForm.resetFields(['projectId', 'responsiblePersonId']);
    };
    const onSelectProjectId = (projectId: number) => {
      loadResponsibles({ projectIds: [projectId], personStatusIds: [3] });
      editApplicationForm.resetFields(['responsiblePersonId']);
    };

    return (
      <>
        <Row gutter={20}>
          <Col span={3}>
            <Form.Item name="appTypeId" label="Тип просьбы" rules={[{ required: true, message: '' }]}>
              <Select
                placeholder="Выберите из списка"
                showSearch
                filterOption={selectFilterOptionIcon}
                disabled={
                  application?.statusId !== AppStatusAliasesEnum['Принята в обработку'] &&
                  application?.statusId !== AppStatusAliasesEnum['Новая']
                }
              >
                {appType?.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="subjectId" label="Вид помощи" rules={[{ required: true, message: '' }]}>
              <Select placeholder="Вид помощи" allowClear showSearch filterOption={selectFilterOptionIcon}>
                {Array.isArray(appSubject) &&
                  [...appSubject]
                    .filter((el) => !el.deleted)
                    .map(({ id, name }) => (
                      <Select.Option key={id} value={id}>
                        <div
                          className="display-flex"
                          style={{ justifyContent: 'center', alignItems: 'center' }}
                          title={name}
                        >
                          <div
                            className="display-flex icon-application-select"
                            style={{ alignItems: 'center', minWidth: 20, maxWidth: 20 }}
                          >
                            {findDynamicSVGReactElement('app_subject', 'help', 'iconName', id)}
                          </div>
                          <span className="ml5 ant-select-item-option-content">{name}</span>
                        </div>
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="regularityAssistanceId" label="Регулярность помощи">
              <Select placeholder="Выберите из списка" allowClear>
                {regularityOfAssistance
                  .filter((el) => !el.deleted)
                  .map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="regionId" label="Субъект РФ" rules={[{ required: true, message: '' }]}>
              <Select
                placeholder="Выберите из списка"
                onSelect={onSelectRegionId}
                disabled={!hasAccess(['APPLICATIONS.READ.ALLREGIONS'])}
              >
                {region.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item dependencies={['regionId']} className="form-item-dependencies">
              {({ getFieldValue }) => {
                const regionId = getFieldValue('regionId');
                return (
                  <Form.Item name="projectId" label="Проект" rules={[{ required: true, message: '' }]}>
                    <Select
                      placeholder={regionId ? 'Выберите из списка' : 'Выберите субъект РФ'}
                      disabled={!regionId}
                      onSelect={onSelectProjectId}
                      loading={checkIsLoading(projectsLoading)}
                      showSearch
                      allowClear
                      filterOption={selectFilterOption}
                    >
                      {Array.isArray(projects) &&
                        projects
                          .filter((project) => project.regionId === regionId)
                          .filter((project) => project.projectTypeId === ProjectTypes.Активные)
                          .map(({ id, title }) => (
                            <Select.Option key={id} value={id}>
                              {title}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item dependencies={['regionId', 'projectId']} className="form-item-dependencies">
              {({ getFieldValue }) => {
                const projectId = getFieldValue('projectId');
                return (
                  <Form.Item
                    name="responsiblePersonId"
                    label="Ответственный"
                    rules={[
                      {
                        required: application?.statusId !== appStatusAliases.neew,
                        message: '',
                      },
                    ]}
                  >
                    <Select
                      placeholder={projectId ? 'Выберите из списка' : 'Выберите проект'}
                      disabled={!projectId}
                      loading={checkIsLoading(responsiblesLoading)}
                      showSearch
                      allowClear
                      filterOption={selectFilterOption}
                    >
                      {Array.isArray(responsibles) &&
                        responsibles.map(({ id, firstName, lastName, middleName }) => {
                          const fio = getFio({
                            firstName,
                            lastName,
                            middleName,
                          });
                          return (
                            <Select.Option key={id} value={id}>
                              {fio}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          {application?.appTypeId !== 2 && (
            <Col span={3}>
              <Form.Item name="numberOfPerformers" label="Число исполнителей">
                <Input placeholder="Число" />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={20}>
          <Col span={16}>
            <Form.Item name="appText" label="Просьба" className="create-application__dscTextArea">
              <Input.TextArea placeholder="Текст просьбы" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item name="deadline" label="Срок завершения">
                  <DatePicker
                    format={displayFormat}
                    disabledDate={(date) => date.isBefore(moment(application?.createDate, 'YYYY-MM-DD'), 'day')}
                    inputRender={(props) => {
                      return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="urgent" valuePropName="checked" className="mt20">
                  <Checkbox>
                    <span style={{ fontSize: '16px' }}>Срочная просьба</span>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item name="executionDate" label="Дата исполнения">
                  <DatePicker
                    disabledDate={(date) => date.isBefore(moment(), 'day')}
                    format={displayFormat}
                    inputRender={(props) => {
                      return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="executionTime" label=" ">
                  <Input placeholder="Укажите время исполнения" maxLength={18} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <h3 className="text-bold mb3 mt5">Обработка просьбы</h3>
      <Divider className="thin-divider mt0 mb7" />
      {!editModeMain && renderProcessing()}
      {editModeMain && renderProcessingEditing()}
    </>
  );
};
