import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row, Space } from 'antd';
import GeneralBlock from '../FileCard/GeneralBlock';
import PersonalInfoBlock from '../FileCard/PersonalInfoBlock';
import PersonContactsBlock from '../FileCard/PersonContactsBlock';
import WorkEducationBlock from '../FileCard/WorkEducationBlock';
import ReligionBlock from '../FileCard/ReligionBlock';
import HealthBlock from '../FileCard/HealthBlock';
import ServiceInformationBlock from '../FileCard/ServiceInformationBlock';
import { PersonTypes } from '../FileCard/PersonTypes';
import SocialBlock from '../FileCard/SocialBlock';
import { useAppSelector } from '../../store/hooks';
import './UserForm.less';
import usePermissions from '../../lib/utils/usePermissions';
import {
  PERSON_READ,
  PERSON_READ_ALLREGIONS,
  PERSON_READ_FULLACCESS,
  PERSON_STATUS_WRITE,
  PERSON_WRITE,
  PERSON_WRITE_ALLREGIONS,
} from '../../lib/const/permissions';
import AgreementBlock from '../FileCard/AgreementBlock';

const rowStyles = {
  marginTop: 10,
};

interface Props {
  initialValues: any;
  isEditMode: boolean;
  isNewUser?: boolean;
  form: any;
  handleArchive?: () => void;
  image?: any;
  handleNewImage: (file, route) => void;
  renderButtonsTop?: () => React.JSX.Element | null;
  isImageLoading?: boolean;
}

function UserForm(props: Props) {
  const {
    initialValues,
    isEditMode,
    form,
    handleArchive,
    isNewUser,
    image,
    handleNewImage,
    renderButtonsTop,
    isImageLoading,
  } = props;
  const [hasAccess] = usePermissions();
  const { personFullCard, meUserFull } = useAppSelector((state) => state.persons);

  const [isArchiveModalOpened, setIsArchiveModalOpened] = useState(false);

  const isSameRegion = meUserFull?.regionId === personFullCard?.regionId;
  const isArchived = personFullCard?.personStatusId === 5;

  const shouldArchiveButtonRender = () => {
    if (isEditMode) return false;
    if (isNewUser) return false;
    if (isArchived) return false;
    if (personFullCard?.personTypeId === PersonTypes.Подопечный) return false;
    if (hasAccess([PERSON_STATUS_WRITE]) && hasAccess([PERSON_WRITE_ALLREGIONS])) return true;
    else if (hasAccess([PERSON_WRITE]) && isSameRegion) return true;
    return false;
  };

  const shouldArchiveAgreementRender = () => {
    if (hasAccess([PERSON_READ_FULLACCESS])) return true;
    return false;
  };

  const isAgreementView = shouldArchiveAgreementRender();

  return (
    <Form layout="vertical" form={form} initialValues={initialValues} className="user-form-component">
      <Modal open={isArchiveModalOpened} footer={false} closable={false}>
        <p>
          Перевод анкеты в статус "Архив" приведёт к удалению учетной записи и персональных данных анкеты. Вы
          подтверждаете изменение?
        </p>
        <div style={{ textAlign: 'center' }}>
          <Space>
            <Button
              onClick={() => {
                setIsArchiveModalOpened(false);
              }}
            >
              Отмена
            </Button>
            <Button type="primary" onClick={handleArchive}>
              Подтвердить
            </Button>
          </Space>
        </div>
      </Modal>

      <Row style={rowStyles} gutter={10} wrap={false}>
        <Col span={isAgreementView ? 6 : undefined} style={{ width: isAgreementView ? undefined : '100%' }}>
          <GeneralBlock isEditMode={isEditMode} handleArchive={handleArchive} isNewUser={isNewUser} />
        </Col>
        <Col style={{ width: isAgreementView ? '100%' : undefined }}>
          {isAgreementView && <AgreementBlock isEditMode={isEditMode} isNewUser={isNewUser} />}
        </Col>
        <Col>
          <Space align="start">
            {renderButtonsTop && renderButtonsTop()}

            {shouldArchiveButtonRender() && (
              <Button type="primary" onClick={() => setIsArchiveModalOpened(true)}>
                Перевести в архив
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <Row style={rowStyles} gutter={10}>
        <Col span={14}>
          <PersonalInfoBlock
            isEditMode={isEditMode}
            isNewUser={isNewUser}
            image={image}
            handleNewImage={handleNewImage}
            isImageLoading={isImageLoading}
          />
        </Col>
        <Col span={10}>
          <PersonContactsBlock isEditMode={isEditMode} isNewUser={isNewUser} />
        </Col>
      </Row>
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
        <Row style={rowStyles} gutter={10}>
          <Col span={14}>
            <WorkEducationBlock isEditMode={isEditMode} isNewUser={isNewUser} />
          </Col>
          <Col span={10}>
            <ReligionBlock isEditMode={isEditMode} isNewUser={isNewUser} />
          </Col>
        </Row>
      )}
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
        <Row style={rowStyles} gutter={10}>
          <Col span={24}>
            <HealthBlock isEditMode={isEditMode} isNewUser={isNewUser} />
          </Col>
        </Row>
      )}
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
        <Row style={rowStyles} gutter={10}>
          <Col span={24}>
            <ServiceInformationBlock isEditMode={isEditMode} isNewUser={isNewUser} />
          </Col>
        </Row>
      )}
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) &&
        (personFullCard?.personTypeId === PersonTypes.Подопечный || isNewUser) && (
          <Row style={rowStyles} gutter={10}>
            <Col span={24}>
              <SocialBlock isEditMode={isEditMode} isNewUser={isNewUser} />
            </Col>
          </Row>
        )}
    </Form>
  );
}

export default UserForm;
