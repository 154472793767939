import React from 'react';
import PersonPhoto from '../FileCard/PersonPhoto';
import { Button, message, Upload } from 'antd';
import axios from 'axios';
import { getPersonAvatarRoute, getPersonPhotoRoute } from '../../lib/routes/persons';
import { RcFile } from 'antd/es/upload';
import { useAppSelector } from '../../store/hooks';
import ImgCrop from 'antd-img-crop';
import usePermissions from '../../lib/utils/usePermissions';
import { PERSON_WRITE, PERSON_WRITE_ALLREGIONS } from '../../lib/const/permissions';

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Данный формат не поддерживается. Пожалуйста загрузите файл png, jpeg, jpg');
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('Размер изображения не должен превышать 5Мб.');
  }
  return isJpgOrPng && isLt2M;
};

const beforeCrop = (file: File, _: File[]) => {
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('Размер изображения не должен превышать 5Мб.');
    return false;
  }
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Данный формат не поддерживается. Пожалуйста загрузите файл png, jpeg, jpg');
    return false;
  }
  return isLt2M;
};

interface Props {
  myProfile: boolean;
  image?: any;
  handleNewImage: (file, route) => void;
  isImageLoading?: boolean;
}

function ProfileImage(props: Props) {
  const { image, myProfile, handleNewImage, isImageLoading } = props;
  const { meUserFull, personFullCard } = useAppSelector((state) => state.persons);
  const [hasAccess] = usePermissions();

  const route = () => (myProfile ? getPersonAvatarRoute(meUserFull?.id) : getPersonPhotoRoute(personFullCard?.id));

  //TODO Rostislav сделать без перезагрузки
  const handleDelete = () => {
    axios
      .delete(route())
      .then(() => message.success('Изображение удалено'))
      .then(() => window.location.reload());
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <PersonPhoto myProfile={myProfile} image={image} isLoading={isImageLoading} />
      {(hasAccess([PERSON_WRITE, PERSON_WRITE_ALLREGIONS]) || myProfile) && (
        <ImgCrop rotate modalTitle="Редактирование аватара" beforeCrop={beforeCrop}>
          <Upload
            name="avatar"
            beforeUpload={beforeUpload}
            showUploadList={false}
            customRequest={({ file }) => {
              handleNewImage(file, route);
            }}
          >
            <Button className="mt10" type="primary">
              Загрузить изображение
            </Button>
          </Upload>
        </ImgCrop>
      )}

      {(hasAccess([PERSON_WRITE, PERSON_WRITE_ALLREGIONS]) || myProfile) && (
        <Button className="mt10" onClick={handleDelete} disabled={!image}>
          Очистить
        </Button>
      )}
    </div>
  );
}

export default ProfileImage;
