import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import requestStatusCodes from '../../../lib/const/requestStatusCodes';
import MaritalStatus from '../../../lib/interfaces/MaritalStatus';
import { RequestStatuses } from '../../../lib/types/RequestStatuses';
import axios from 'axios';
import { getDictionariesByType, getDictionaryByType } from '../../../lib/routes/dictionaries';
import DictionaryItem from '../../../lib/interfaces/DictionaryItem';
import { DicTypeId } from '../../../lib/interfaces/DicTypeId';
import { camelizeString } from '../../../lib/utils/camelizeString';
import { getSortedDictionaries } from '../../../lib/utils/getSortedDictionaries';

interface InitialState {
  authRole: DictionaryItem[];
  authRoleLoading: RequestStatuses;
  authPermission: DictionaryItem[];
  authPermissionLoading: RequestStatuses;
  maritalStatus: MaritalStatus[];
  maritalStatusLoading: RequestStatuses;
  region: DictionaryItem[];
  regionLoading: RequestStatuses;
  personCategory: DictionaryItem[];
  personCategoryLoading: RequestStatuses;
  appSubject: DictionaryItem[];
  appSubjectLoading: RequestStatuses;
  regularityOfAssistance: DictionaryItem[];
  regularityOfAssistanceLoading: RequestStatuses;
  subwayStation: DictionaryItem[];
  subwayStationLoading: RequestStatuses;
  phoneType: DictionaryItem[];
  phoneTypeLoading: RequestStatuses;
  appStatus: DictionaryItem[];
  appStatusLoading: RequestStatuses;
  personType: DictionaryItem[];
  personTypeLoading: RequestStatuses;
  personTypes: DictionaryItem[];
  personTypesLoading: RequestStatuses;
  personStatuses: DictionaryItem[];
  personStatusesLoading: RequestStatuses;
  appCloseReason: DictionaryItem[];
  appCloseReasonLoading: RequestStatuses;
  actionType: DictionaryItem[];
  actionTypeLoading: RequestStatuses;
  actionTypes: DictionaryItem[];
  actionTypesLoading: RequestStatuses;
  appType: DictionaryItem[];
  appTypesLoading: RequestStatuses;
  education: DictionaryItem[];
  educationLoading: RequestStatuses;
  personCategories: DictionaryItem[];
  personCategoriesLoading: RequestStatuses;
  awarenessSource: DictionaryItem[];
  awarenessSourceLoading: RequestStatuses;
  projectType: DictionaryItem[];
  projectTypeLoading: RequestStatuses;
  contractStatuses: DictionaryItem[];
  contractStatusesLoading: RequestStatuses;
}

const initialState: InitialState = {
  authPermission: [],
  authPermissionLoading: requestStatusCodes.idle,
  authRole: [],
  authRoleLoading: requestStatusCodes.idle,
  maritalStatus: [],
  maritalStatusLoading: requestStatusCodes.idle,
  region: [],
  regionLoading: requestStatusCodes.idle,
  personCategory: [],
  personCategoryLoading: requestStatusCodes.idle,
  appSubject: [],
  appSubjectLoading: requestStatusCodes.idle,
  regularityOfAssistance: [],
  regularityOfAssistanceLoading: requestStatusCodes.idle,
  subwayStation: [],
  subwayStationLoading: requestStatusCodes.idle,
  phoneType: [],
  phoneTypeLoading: requestStatusCodes.idle,
  appStatus: [],
  appStatusLoading: requestStatusCodes.idle,
  personType: [],
  personTypeLoading: requestStatusCodes.idle,
  personTypes: [],
  personTypesLoading: requestStatusCodes.idle,
  personStatuses: [],
  personStatusesLoading: requestStatusCodes.idle,
  appCloseReason: [],
  appCloseReasonLoading: requestStatusCodes.idle,
  actionType: [],
  actionTypeLoading: requestStatusCodes.idle,
  actionTypes: [],
  actionTypesLoading: requestStatusCodes.idle,
  appType: [],
  appTypesLoading: requestStatusCodes.idle,
  education: [],
  educationLoading: requestStatusCodes.idle,
  personCategories: [],
  personCategoriesLoading: requestStatusCodes.idle,
  awarenessSource: [],
  awarenessSourceLoading: requestStatusCodes.idle,
  projectType: [],
  projectTypeLoading: requestStatusCodes.idle,
  contractStatuses: [],
  contractStatusesLoading: requestStatusCodes.idle,
};

// Метод загружает словари списком. Каждый словарь и его Loading меняется соответственно
export const getDictionaries = createAsyncThunk('dictionaries/getDictionaries', async (types: DicTypeId[]) => {
  const response = axios.get(getDictionariesByType(types));
  const { data } = await response;
  return data;
});

const getMaritalStatusDictionary = createAsyncThunk('dictionaries/getMaritalStatusDictionary', async () => {
  const response = axios.get(getDictionaryByType('marital_status'));
  const { data } = await response;
  return data;
});

const getRegionDictionary = createAsyncThunk('dictionaries/getRegionDictionary', async () => {
  const response = axios.get(getDictionaryByType('region'));
  const { data } = await response;
  return data;
});

const getAwarenessSourceDictionary = createAsyncThunk('dictionaries/getAwarenessSourceDictionary', async () => {
  const response = axios.get(getDictionaryByType('awareness_source'));
  const { data } = await response;
  return data;
});

const getEducationDictionary = createAsyncThunk('dictionaries/getEducationDictionary', async () => {
  const response = axios.get(getDictionaryByType('education'));
  const { data } = await response;
  return data;
});

export const getSubwayStationDictionary = createAsyncThunk('dictionaries/getSubwayStationDictionary', async () => {
  const response = axios.get(getDictionaryByType('subway_station'));
  const { data } = await response;
  return data;
});

const getPersonCategoryDictionary = createAsyncThunk('dictionaries/getPersonCategoryDictionary', async () => {
  const response = axios.get(getDictionaryByType('person_category'));
  const { data } = await response;
  return data;
});

const getPersonStatusesDictionary = createAsyncThunk('dictionaries/getPersonStatusesDictionary', async () => {
  const response = axios.get(getDictionaryByType('person_status'));
  const { data } = await response;
  return data;
});

const getPersonTypesDictionary = createAsyncThunk('dictionaries/getPersonTypesDictionary', async () => {
  const response = axios.get(getDictionaryByType('person_type'));
  const { data } = await response;
  return data;
});

const getActionTypesDictionary = createAsyncThunk('dictionaries/getActionTypesDictionary', async () => {
  const response = axios.get(getDictionaryByType('action_type'));
  const { data } = await response;
  return data;
});

const getAppTypesDictionary = createAsyncThunk('dictionaries/getAppTypesDictionary', async () => {
  const response = axios.get(getDictionaryByType('app_type'));
  const { data } = await response;
  return data;
});

const getContractStatusesDictionary = createAsyncThunk('dictionaries/getContractStatusesDictionary', async () => {
  const response = axios.get(getDictionaryByType('contract_status'));
  const { data } = await response;
  return data;
});

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMaritalStatusDictionary.pending, (state) => {
        state.maritalStatusLoading = requestStatusCodes.pending;
      })
      .addCase(getMaritalStatusDictionary.fulfilled, (state, action: PayloadAction<MaritalStatus[]>) => {
        state.maritalStatusLoading = requestStatusCodes.fulfilled;
        state.maritalStatus = getSortedDictionaries(action.payload, 'marital_status');
      })
      .addCase(getMaritalStatusDictionary.rejected, (state) => {
        state.maritalStatusLoading = requestStatusCodes.rejected;
      })
      .addCase(getRegionDictionary.pending, (state) => {
        state.regionLoading = requestStatusCodes.pending;
      })
      .addCase(getRegionDictionary.fulfilled, (state, action: PayloadAction<DictionaryItem[]>) => {
        state.regionLoading = requestStatusCodes.fulfilled;
        state.region = getSortedDictionaries(action.payload, 'region');
      })
      .addCase(getRegionDictionary.rejected, (state) => {
        state.regionLoading = requestStatusCodes.rejected;
      })
      .addCase(getSubwayStationDictionary.pending, (state) => {
        state.subwayStationLoading = requestStatusCodes.pending;
      })
      .addCase(getSubwayStationDictionary.rejected, (state) => {
        state.subwayStationLoading = requestStatusCodes.rejected;
      })
      .addCase(getSubwayStationDictionary.fulfilled, (state, { payload }) => {
        state.subwayStationLoading = requestStatusCodes.fulfilled;
        state.subwayStation = getSortedDictionaries(payload, 'subway_station');
      })
      .addCase(getPersonTypesDictionary.pending, (state) => {
        state.personTypesLoading = requestStatusCodes.pending;
      })
      .addCase(getPersonTypesDictionary.rejected, (state) => {
        state.personTypesLoading = requestStatusCodes.rejected;
      })
      .addCase(getPersonTypesDictionary.fulfilled, (state, { payload }) => {
        state.personTypesLoading = requestStatusCodes.fulfilled;
        state.personTypes = getSortedDictionaries(payload, 'person_type');
      })
      .addCase(getPersonStatusesDictionary.pending, (state) => {
        state.personStatusesLoading = requestStatusCodes.pending;
      })
      .addCase(getPersonStatusesDictionary.rejected, (state) => {
        state.personStatusesLoading = requestStatusCodes.rejected;
      })
      .addCase(getPersonStatusesDictionary.fulfilled, (state, { payload }) => {
        state.personStatusesLoading = requestStatusCodes.fulfilled;
        state.personStatuses = getSortedDictionaries(payload, 'person_status');
      })
      .addCase(getDictionaries.pending, (state, { meta: { arg: types } }) => {
        types.map(camelizeString).forEach((dicName) => {
          state[`${dicName}Loading`] = requestStatusCodes.pending;
        });
      })
      .addCase(getDictionaries.rejected, (state, { meta: { arg: types } }) => {
        types.map(camelizeString).forEach((dicName) => {
          state[`${dicName}Loading`] = requestStatusCodes.rejected;
        });
      })
      .addCase(getDictionaries.fulfilled, (state, { payload }) => {
        for (const diTypeId in payload) {
          const dicName = camelizeString(diTypeId);
          state[`${dicName}Loading`] = requestStatusCodes.fulfilled;
          state[dicName] = getSortedDictionaries(payload[diTypeId], diTypeId);
        }
      })
      .addCase(getActionTypesDictionary.pending, (state) => {
        state.actionTypesLoading = requestStatusCodes.pending;
      })
      .addCase(getActionTypesDictionary.rejected, (state) => {
        state.actionTypesLoading = requestStatusCodes.rejected;
      })
      .addCase(getActionTypesDictionary.fulfilled, (state, { payload }) => {
        state.actionTypesLoading = requestStatusCodes.fulfilled;
        state.actionTypes = getSortedDictionaries(payload, 'action_type');
      })
      .addCase(getAppTypesDictionary.pending, (state) => {
        state.appTypesLoading = requestStatusCodes.pending;
      })
      .addCase(getAppTypesDictionary.rejected, (state) => {
        state.appTypesLoading = requestStatusCodes.rejected;
      })
      .addCase(getAppTypesDictionary.fulfilled, (state, { payload }) => {
        state.appTypesLoading = requestStatusCodes.fulfilled;
        state.appType = getSortedDictionaries(payload, 'app_type');
      })
      .addCase(getEducationDictionary.pending, (state) => {
        state.educationLoading = requestStatusCodes.pending;
      })
      .addCase(getEducationDictionary.rejected, (state) => {
        state.educationLoading = requestStatusCodes.rejected;
      })
      .addCase(getEducationDictionary.fulfilled, (state, { payload }) => {
        state.educationLoading = requestStatusCodes.fulfilled;
        state.education = getSortedDictionaries(payload, 'education');
      })
      .addCase(getPersonCategoryDictionary.pending, (state) => {
        state.personCategoriesLoading = requestStatusCodes.pending;
      })
      .addCase(getPersonCategoryDictionary.rejected, (state) => {
        state.personCategoriesLoading = requestStatusCodes.rejected;
      })
      .addCase(getPersonCategoryDictionary.fulfilled, (state, { payload }) => {
        state.personCategoriesLoading = requestStatusCodes.fulfilled;
        state.personCategories = getSortedDictionaries(payload, 'person_category');
      })
      .addCase(getAwarenessSourceDictionary.pending, (state) => {
        state.awarenessSourceLoading = requestStatusCodes.pending;
      })
      .addCase(getAwarenessSourceDictionary.rejected, (state) => {
        state.awarenessSourceLoading = requestStatusCodes.rejected;
      })
      .addCase(getAwarenessSourceDictionary.fulfilled, (state, { payload }) => {
        state.awarenessSourceLoading = requestStatusCodes.fulfilled;
        state.awarenessSource = getSortedDictionaries(payload, 'awareness_source');
      })
      .addCase(getContractStatusesDictionary.pending, (state) => {
        state.contractStatusesLoading = requestStatusCodes.pending;
      })
      .addCase(getContractStatusesDictionary.rejected, (state) => {
        state.contractStatusesLoading = requestStatusCodes.rejected;
      })
      .addCase(getContractStatusesDictionary.fulfilled, (state, { payload }) => {
        state.contractStatusesLoading = requestStatusCodes.fulfilled;
        state.contractStatuses = getSortedDictionaries(payload, 'contract_status');
      });
  },
});

const { reducer } = dictionariesSlice;

export {
  reducer,
  getMaritalStatusDictionary,
  getRegionDictionary,
  getPersonTypesDictionary,
  getPersonStatusesDictionary,
  getAppTypesDictionary,
  getActionTypesDictionary,
  getEducationDictionary,
  getPersonCategoryDictionary,
  getAwarenessSourceDictionary,
  getContractStatusesDictionary,
};
