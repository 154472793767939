import React, { FC, useState } from 'react';
import { Form, Modal, Button, Spin, DatePicker, Select, Row, Col, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { cardsReportRoute } from '../../lib/routes/applications';
import { displayFormat } from '../../lib/utils/formatDate';
import { useAppSelector } from '../../store/hooks';
import MultipleSelect from '../common/MultipleSelect';
import selectFilterOption from '../../lib/utils/selectFilterOption';
import { PersonTypes } from '../FileCard/PersonTypes';
import { hasAccess } from '../../lib/utils/usePermissions';

interface IProps {
  visible: boolean;
  closeModal: () => void;
}

export const FilesReportModal: FC<IProps> = ({ visible, closeModal }) => {
  const { personTypes, personStatuses, region, contractStatuses } = useAppSelector((state) => state.dictionaries);
  const { meUserFull } = useAppSelector((state) => state.persons);

  const [reportForm] = Form.useForm();
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  const handleCloseModal = () => {
    reportForm.resetFields();
    closeModal();
  };

  const onSelectPersonType = () => {
    reportForm.resetFields(['personStatusIds']);
  };

  const hasAccessOnlyOwnRegion = hasAccess(['PERSONS.REPORT']) && !hasAccess(['PERSONS.REPORT.ALLREGIONS']);

  const onFinish = (values) => {
    setIsLoadingReport(true);

    const requestData = {
      dateFrom: values.range[0]?.format('YYYY-MM-DD'),
      dateTo: values.range[1]?.format('YYYY-MM-DD'),
      regionIds: values.regionIds,
      personTypeIds: values.personTypeIds,
      personStatusIds: values.personStatusIds,
      contractStatusIds: values.contractStatusIds,
      contractEndDateFrom: values?.contractRange?.[0]?.format('YYYY-MM-DD'),
      contractEndDateTo: values?.contractRange?.[1]?.format('YYYY-MM-DD'),
    };

    axios
      .post(cardsReportRoute(), requestData, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        saveAs(data, `Отчет_${moment().format('DD_MM_YYYY')}`);
        handleCloseModal();
        message.success('Отчёт сформирован', 3);
      })
      .catch(() => {
        message.error('Произошла ошибка. Пожалуйста, попробуйте сформировать отчёт позднее', 3);
      })
      .finally(() => {
        setIsLoadingReport(false);
      });
  };

  const lableCol = { span: 10 };
  const wrapperCol = { span: 12 };

  return (
    <Modal visible={visible} footer={false} onCancel={handleCloseModal} width="563px">
      <Spin spinning={isLoadingReport}>
        <Form
          form={reportForm}
          onFinish={onFinish}
          initialValues={hasAccessOnlyOwnRegion ? { regionIds: [meUserFull?.regionId] } : {}}
        >
          <Form.Item
            name="range"
            label="Период выгрузки"
            labelCol={lableCol}
            wrapperCol={wrapperCol}
            labelAlign="left"
            rules={[{ required: true, message: '' }]}
          >
            <DatePicker.RangePicker
              allowClear
              format={displayFormat}
              placeholder={['с', 'по']}
              disabledDate={(date) => date.isAfter(moment())}
              defaultPickerValue={[moment().subtract(1, 'month'), moment()]}
            />
          </Form.Item>

          <Form.Item
            name="regionIds"
            label="Субъект"
            labelCol={lableCol}
            wrapperCol={wrapperCol}
            labelAlign="left"
            rules={[{ required: true, message: '' }]}
          >
            <MultipleSelect
              placeholder="Выберите из списка"
              allowClear
              allValues={region.map(({ id }) => id)}
              maxTagCount={1}
              maxTagTextLength={11}
              disabled={!hasAccess(['PERSONS.REPORT.ALLREGIONS'])}
              showSearch
              showArrow
              filterOption={selectFilterOption}
              style={{ width: 216 }}
            >
              {region.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </MultipleSelect>
          </Form.Item>

          <Form.Item
            name="personTypeIds"
            label="Тип пользователя"
            labelCol={lableCol}
            labelAlign="left"
            wrapperCol={wrapperCol}
            rules={[{ required: true, message: '' }]}
          >
            <MultipleSelect
              placeholder="Выберите из списка"
              allowClear
              allValues={personTypes.map(({ id }) => id)}
              maxTagCount={1}
              maxTagTextLength={8}
              disabled={personTypes.length === 0}
              showSearch
              showArrow
              filterOption={selectFilterOption}
              style={{ width: 216 }}
              onSelect={onSelectPersonType}
              onClear={onSelectPersonType}
            >
              {personTypes.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </MultipleSelect>
          </Form.Item>

          <Form.Item dependencies={['personTypeIds']} className="form-item-dependencies">
            {({ getFieldValue }) => {
              const personTypeIds: number[] = getFieldValue('personTypeIds') || [];
              const findDisabledPersonType = personTypeIds.some((id) => id === PersonTypes.Подопечный);
              return (
                <div>
                  <Form.Item
                    name="personStatusIds"
                    label="Статус пользователя"
                    labelCol={lableCol}
                    wrapperCol={wrapperCol}
                    labelAlign="left"
                  >
                    <MultipleSelect
                      placeholder="Выберите из списка"
                      allowClear
                      allValues={personStatuses.map(({ id }) => id)}
                      maxTagCount={1}
                      maxTagTextLength={8}
                      disabled={personTypeIds.length === 0 || personStatuses.length === 0 || findDisabledPersonType}
                      showSearch
                      showArrow
                      filterOption={selectFilterOption}
                      style={{ width: 216 }}
                    >
                      {Array.isArray(personStatuses) &&
                        personStatuses.map(({ id, name }) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                    </MultipleSelect>
                  </Form.Item>
                </div>
              );
            }}
          </Form.Item>

          <Form.Item
            name="contractRange"
            label="Дата окончания договора"
            labelCol={lableCol}
            wrapperCol={wrapperCol}
            labelAlign="left"
          >
            <DatePicker.RangePicker
              allowClear
              format={displayFormat}
              placeholder={['с', 'по']}
              // disabledDate={(date) => date.isAfter(moment())}
              defaultPickerValue={[moment().subtract(1, 'month'), moment()]}
            />
          </Form.Item>

          <Form.Item
            name="contractStatusIds"
            label="Статус договора"
            labelCol={lableCol}
            wrapperCol={wrapperCol}
            labelAlign="left"
          >
            <MultipleSelect
              placeholder="Выберите из списка"
              allowClear
              allValues={contractStatuses.map(({ id }) => id)}
              maxTagCount={1}
              maxTagTextLength={8}
              disabled={contractStatuses.length === 0}
              showSearch
              showArrow
              filterOption={selectFilterOption}
              style={{ width: 216 }}
            >
              {contractStatuses.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </MultipleSelect>
          </Form.Item>

          <Row justify="center" gutter={20} className="mt10">
            <Col>
              <Button onClick={handleCloseModal}>Отмена</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                Сформировать
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
