export const getSortedDictionaries = (dictionaries, dicName) => {
  const firstGroup = [
    'app_close_reason',
    'app_status',
    'app_subject',
    'awareness_source',
    'education',
    'marital_status',
    'person_category',
    'region',
    'regularity_of_assistance',
    'subway_station',
  ];

  const secondGroup = [
    'address_type',
    'app_type',
    'auth_role',
    'contract_status',
    'person_status',
    'person_type',
    'phone_type',
  ];

  // Если словарь относится к первой группе
  if (firstGroup.includes(dicName)) {
    return [...dictionaries]
      .sort((a, b) => {
        // Проверяем первые буквы
        const isAEng = /^[A-Za-z]/.test(a.name);
        const isBEng = /^[A-Za-z]/.test(b.name);

        // Если a - английский, а b - русский, то a идёт выше
        if (isAEng && !isBEng) return -1;
        if (!isAEng && isBEng) return 1;

        // Если оба одного типа, сортируем по алфавиту
        return a.name.localeCompare(b.name, 'ru', { sensitivity: 'base' });
      })
      .sort((a, b) => {
        if (a.dsc && !b.dsc) return -1; // С описанием выше
        if (!a.dsc && b.dsc) return 1;

        // Если у обоих есть dsc, сортируем по алфавиту + числам
        if (a.dsc && b.dsc) {
          return a.dsc.localeCompare(b.dsc, undefined, { numeric: true });
        }
        return 0;
      })
      .sort((a, b) => a.deleted - b.deleted); // Удаленные в конце
  }

  // Если словарь относится ко второй группе
  if (secondGroup.includes(dicName)) {
    return [...dictionaries]
      .sort((a, b) => a.id - b.id) // Сортировка по ID
      .sort((a, b) => a.deleted - b.deleted); // Удаленные в конце
  }

  // Если сортировка не требуется (третья группа)
  return dictionaries;
};
