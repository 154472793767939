import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Divider, Modal, Spin } from 'antd';
import axios from 'axios';
import './PasswordForgotten.less';
import { forgottenPasswordRoute } from '../../lib/routes/routes';
import { blockedSpaceKeyDown, clearSpaceOnInput } from '../../lib/utils/clearSpaceInput';

const PasswordForgotten = () => {
  const navigate = useNavigate();
  const [passwordForm] = Form.useForm();
  const [loadingData, setLoadingData] = useState(false);

  const handleSubmit = ({ email }) => {
    setLoadingData(true);
    axios
      .post(forgottenPasswordRoute(email))
      .then(() => {
        Modal.success({
          content: (
            <>
              <div>Письмо с инструкцией по восстановлению пароля успешно</div>
              <div>выслано на адрес электронной почты, привязанный</div>
              <div>к Вашей учетной записи</div>
            </>
          ),
          className: 'password-forgotten-info-modal',
          width: '530px',
          bodyStyle: {
            backgroundColor: '#F4F4F4',
            paddingTop: '20px',
            marginTop: '111px',
          },
          okButtonProps: {
            style: {
              marginRight: '158px',
            },
          },
          onOk: () => {
            navigate('/login');
          },
        });
      })
      .catch(({ response }) => {
        const errorTitle = response.status === 422 ? 'Пользователь с таким логином не найден' : 'Произошла ошибка';
        const errorMessage = response.status === 422 ? '' : 'Пожалуйста, попробуйте позднее';

        Modal.error({
          title: errorTitle,
          content: errorMessage,
          className: 'password-forgotten-info-modal',
          width: '530px',
          bodyStyle: {
            backgroundColor: '#F4F4F4',
            paddingTop: '20px',
            marginTop: '111px',
          },
          okButtonProps: {
            style: {
              marginRight: '158px',
            },
          },
          onOk: () => {
            if (response.status === 422) {
              passwordForm.setFields([{ name: 'email', errors: [' '] }]);
            }
          },
        });
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  return (
    <div className="password-forgotten-container">
      <div className="password-forgotten-innerContainer">
        <div className="logo mb15" />
        <h1>Забыли пароль?</h1>
        <Divider />
        <Spin spinning={loadingData}>
          <Form
            form={passwordForm}
            name="basic"
            initialValues={{ remember: true }}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <div className="font-roboto text-left ml5">Укажите почту, привязанную к вашей учетной записи. Мы</div>
            <Form.Item
              label={<span>отправим письмо для восстановления пароля.</span>}
              name="email"
              rules={[
                { required: true, message: 'Введите корректный e-mail' },
                {
                  type: 'email',
                  message: 'Введите корректный e-mail',
                },
              ]}
              className="form-item-required__error"
            >
              <Input
                placeholder="Введите e-mail"
                maxLength={64}
                allowClear
                onKeyDown={blockedSpaceKeyDown}
                onInput={clearSpaceOnInput}
              />
            </Form.Item>
            <div className="password-forgotten-buttons" style={{ width: '384px' }}>
              <Button htmlType="submit">
                <Link to="/login">Закрыть</Link>
              </Button>
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => {
                  const email = getFieldValue('email');
                  return (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ml20 password-forgotten-submitBtn"
                      disabled={!email}
                    >
                      Восстановить пароль
                    </Button>
                  );
                }}
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default PasswordForgotten;
