import React, { useEffect, useState } from 'react';
import UserProfile from '../../components/UserProfile/UserProfile';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import {
  getPersonCategoryDictionary,
  getPersonStatusesDictionary,
  getPersonTypesDictionary,
} from '../../store/slices/dictionariesSlice/dictionariesSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Spin } from 'antd';
import axios, { AxiosResponse } from 'axios';
import { profileRoute } from '../../lib/routes/profile';
import moment from 'moment';
import { toYearMonthDayLineFormat } from '../../lib/utils/formatDate';
import { fetchMeUserFull, getPersonProfileShortInfo } from '../../store/slices/personsSlice/personsSlice';
import { getPersonAvatarRoute } from '../../lib/routes/persons';
import { changeUserPasswordRoute } from '../../lib/routes/users';
import { message } from 'antd/es';
import { getSubscriptions } from '../../store/slices/subscriptionsSlice/subscriptionsSlice';
import { useDictionaries } from '../../lib/utils/useDictionaries';
import { getProjects } from '../../store/slices/projectsSlice/projectsSlice';
import { updateSubscriptionsRoute } from '../../lib/routes/subscriptions';
import { FieldsSubscriptions, SubscriptionsUpdateParams } from '../../lib/interfaces/Subscriptions';

function UserProfileContainer() {
  const dispatch = useAppDispatch();
  const { personTypesLoading, personStatusesLoading, personCategoriesLoading } = useAppSelector(
    (state) => state.dictionaries
  );
  const { meUserFull } = useAppSelector((state) => state.persons);
  const [image, setImage] = useState<any>();
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);

  const { projectsLoading } = useAppSelector((state) => state.projects);

  const { loadDictionaries } = useDictionaries();

  const loadAvatar = () => {
    setIsImageLoading(true);
    axios
      .get(getPersonAvatarRoute(meUserFull?.id), {
        responseType: 'arraybuffer',
      })
      .then((res) => {
        setImage(Buffer.from(res.data, 'binary').toString('base64'));
      })
      .finally(() => {
        setIsImageLoading(false);
      });
  };

  const handleNewAvatar = (file, route) => {
    axios
      .post(
        route(),
        { file },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then(() => {
        loadAvatar();
      });
  };

  useEffect(() => {
    loadAvatar();
    dispatch(getPersonProfileShortInfo());
    meUserFull?.userId && dispatch(getSubscriptions(meUserFull.userId));
  }, [dispatch]);

  useEffect(() => {
    if (!checkIsLoaded(personTypesLoading)) dispatch(getPersonTypesDictionary());
    if (!checkIsLoaded(personStatusesLoading)) dispatch(getPersonStatusesDictionary());
    if (!checkIsLoaded(projectsLoading)) dispatch(getProjects({}));
    if (!checkIsLoaded(personCategoriesLoading)) dispatch(getPersonCategoryDictionary());
    loadDictionaries(['app_subject']);
  }, [dispatch]);

  const handleEditProfile = (fields) => {
    axios
      .patch(profileRoute(), {
        firstName: fields.firstName,
        lastName: fields.lastName,
        middleName: fields.noMiddleName ? undefined : fields.middleName,
        phone: fields.phone,
        email: fields.email?.toLowerCase(),
        birthDate: toYearMonthDayLineFormat(moment(fields.birthDate)),
      })
      .then(() => {
        if (meUserFull?.id) dispatch(fetchMeUserFull({ personId: meUserFull.id }));
      });
  };

  const handleChangePassword = (args: { oldPassword: string; newPassword: string }) => {
    const { newPassword, oldPassword } = args;
    if (meUserFull?.userId) {
      axios
        .patch(changeUserPasswordRoute(meUserFull.userId), {
          newPassword,
          oldPassword,
        })
        .then((res) => {
          message.destroy();
          message.success('Пароль успешно изменён');
          return res;
        })
        .catch(() => {
          message.destroy();
          message.error('Произошла ошибка. Пожалуйста, попробуйте позднее');
        });
    }
  };

  const handleEditSubscriptions = (fields: FieldsSubscriptions): Promise<AxiosResponse> => {
    const params: SubscriptionsUpdateParams = {
      fromDate: moment().format(),
      userId: meUserFull && meUserFull.userId,
      notificationTypeId: 15,
      subscriptionCondition: {
        ...fields,
      },
    };

    if (
      params.subscriptionCondition?.projectIds?.length === 0 &&
      params.subscriptionCondition?.appSubjectIds?.length === 0 &&
      params.subscriptionCondition?.personCategoryIds?.length === 0
    ) {
      delete params.subscriptionCondition;
    }

    return axios
      .post(updateSubscriptionsRoute(), params)
      .then((res) => {
        if (meUserFull?.userId) dispatch(getSubscriptions(meUserFull.userId));

        message.success(
          {
            content: 'Изменения сохранены',
          },
          3
        );

        return res;
      })
      .catch((err) => {
        message.error(
          {
            content: 'Произошла ошибка. Пожалуйста, повторите попытку позднее',
          },
          3
        );
        return err;
      });
  };

  return (
    <Spin className="container_height" spinning={checkIsLoading(personTypesLoading, personStatusesLoading)}>
      {checkIsLoaded(personTypesLoading, personStatusesLoading) && (
        <UserProfile
          handleEditProfile={handleEditProfile}
          handleEditSubscriptions={handleEditSubscriptions}
          image={image}
          handleChangePassword={handleChangePassword}
          handleNewImage={handleNewAvatar}
          isImageLoading={isImageLoading}
        />
      )}
    </Spin>
  );
}

export default UserProfileContainer;
