// Удаление пробелов при вводе
export const clearSpaceOnInput = (e) => {
  const input = e.target;
  input.value = input.value.replace(/\s+/g, ''); // Убираем все пробелы
};

// Блокировка ввода пробелов
export const blockedSpaceKeyDown = (e) => {
  if (e.key === ' ') {
    e.preventDefault(); // Запрещаем ввод пробела
  }
};
